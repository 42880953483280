import React, { useState } from 'react'
import FormInput from '../../../Input/input'
import Button from '../../../Button/Button'
import FormStyles from './form.styles.module.css'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
const fs = FormStyles
const schema = z.object({
  name: z
    .string()
    .min(4, { message: 'The username must be 4 characters or more' }),
  email: z.string().email({
    message: 'Invalid email. Please enter a valid email address',
  }),
  message: z.string(),
})
type FormInput = z.infer<typeof schema>
const Form = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
  })
  const handleFormSubmit = async (formData: FormInput) => {
    console.log('submit worhs;', formData)
    try {
      const response = await fetch(
        'https://7ik9s2k445.execute-api.ap-south-1.amazonaws.com/sendemail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            message: formData.message,
            recipient: formData.email,
            to: 'sv.intiriors45@gmail.com',
            // to: 'mohamediqbalm@xbi4.com',
            company_name: 'Sv aluminium',
          }),
        },
      )

      reset()
      if (response.status === 200) {
        alert('Email sent successfully')
        console.log('Email sent successfully')
      } else {
        console.error('Failed to send email')
        alert('Failed to send email')
      }
    } catch (error) {
      console.error('Error sending email:', error)
    }
    setValue('')
  }
  const [value, setValue] = useState('')
  return (
    <div className="flex flex-col gap-8  w-full ">
      {/* <div className="flex flex-col gap-2 p-8"> */}
      <div>Our Team will call you soon</div>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="px-8 flex flex-col gap-5 xl:flex-col lg:gap-10 justify-between text-black "
      >
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormInput
              id="name"
              label="Name"
              inputType="input"
              type="text"
              placeholder="Enter a name"
              className=""
              {...field}
            />
          )}
        />

        {errors.name && (
          <p className="text-red-500">* {`${errors.name.message}`}</p>
        )}
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormInput
              id="email"
              label="Email"
              inputType="input"
              type="email"
              placeholder="Enter an email"
              {...field}
            />
          )}
        />

        {errors.email && (
          <p className="text-red-500">* {`${errors.email.message}`}</p>
        )}

        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormInput
              id="text"
              label="Message"
              inputType="textarea"
              type="textarea"
              placeholder="Enter a message"
              {...field}
            />
          )}
        />

        {errors.message && (
          <p className="text-red-500">* {`${errors.message.message}`}</p>
        )}
        <div className="flex justify-center items-center ">
          <Button type="submit" className="w-[100%]">
            Submit
          </Button>
        </div>
      </form>
      {/* </div> */}
    </div>
  )
}

export default Form

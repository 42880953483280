import React from 'react'
import HeroImage from '../../assets/heroImagesvg.svg'
import Button from '../Button/Button'
import HomeSubComp from './HomeSubComp'
import CircleFrame from '../../assets/updatedRound.svg'
import { Link } from 'react-scroll'
const Home = () => {
  return (
    <div
      className="flex flex-col lg:flex-row p-2 justify-between items-center"
      id="home"
    >
      <div className="flex flex-col justify-between mb-8 lg:mb-0 ml-4 lg:ml-40 w-full lg:w-auto">
        <div className="flex flex-col gap-2 mt-6">
          <div className="text-5xl font-extrabold text-gray-800 leading-none">
            Unlock Elegance in
          </div>
          <div className="text-5xl font-extrabold text-gray-800 leading-none">
            Every Room
          </div>

          <div className="text-xl font-normal text-gray-800 leading-normal">
            Exceptional Interior Wood Design, Aluminum Doors, and
            <div>Windows</div>
          </div>
          <div>
            <Link to="contactus" smooth>
              <Button>Contact us</Button>
            </Link>
          </div>
        </div>
        <div className="w-full lg:w-auto">
          <HomeSubComp />
        </div>
      </div>
      <div className="relative w-full lg:w-auto h-[50vh] lg:h-[75vh] mb-8 lg:mb-0 lg:pr-10">
        <img
          src={HeroImage}
          className="h-full w-full max-w-md max-h-md object-cover"
        />
        <img
          src={CircleFrame}
          className="hidden  sm:block absolute md:hidden lg:block top-1/2 -left-5 transform -translate-y-1/2 -translate-x-full lg:-translate-x-1/4"
        />
      </div>
    </div>
  )
}
export default Home

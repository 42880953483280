import React from 'react'

const Location = () => {
  return (
    <div id="location">
      <h2 className="font-bold  sm:py-6">Location</h2>
      <div className="flex items-center sm:h-screen px-4 sm:px-20">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.5564236084965!2d77.37578757458445!3d8.920763290674737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0429fc01527d9d%3A0xc8785fe5b4154cd6!2sSV%20Interior%20works!5e0!3m2!1sen!2sin!4v1697539591556!5m2!1sen!2sin"
          className="w-[100vw] h-[60vh] sm:w-full sm:h-full"
          style={{
            border: '3px solid #fff',
            borderRadius: '0.4rem',
            zIndex: 1,
          }}
          loading="lazy"
        ></iframe>
      </div>
    </div>
  )
}

export default Location

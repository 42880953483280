import React, { useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import Navbar from '../components/Navbar/Navbar'
import Header from '../components/Header/Header'
import ScrollProgressBar from '../components/progersiveScrollBar/ScrollProgressBar'
const Layout = () => {
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset
      if (currentScrollPosition < lastScrollPosition) {
        setShouldShowNavbar(true) // Scrolling up, show navbar
      } else {
        setShouldShowNavbar(false) // Scrolling down, hide navbar
      }
      setLastScrollPosition(currentScrollPosition)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      // Cleanup
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollPosition]) // Depend on lastScrollPosition
  const navBarClasses = `sticky z-30 transition-all duration-300 ${
    shouldShowNavbar ? 'top-0' : '-top-full'
  }`

  const progressBarClasses = `transition-all duration-500 ${
    shouldShowNavbar ? '' : 'sticky top-0 z-30'
  }`
  return (
    <>
      <div className={navBarClasses}>
        <Header />
        <Navbar />
        <ScrollProgressBar />
      </div>
      <div className={progressBarClasses}>
        <ScrollProgressBar />
      </div>
      <div className="-z-50 w-90vw">
        <Outlet />
      </div>
    </>
  )
}

export default Layout

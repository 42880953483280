import React, { useEffect, useState } from 'react'
import './style.css' // make sure to create appropriate CSS file

const ScrollProgressBar = () => {
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    const totalHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scrollPosition = window.pageYOffset // how much the window is scrolled
    const scrollPercentage = scrollPosition / totalHeight

    setScroll(scrollPercentage)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll) // clean up the event listener
    }
  }, [])
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset
      if (currentScrollPosition < lastScrollPosition) {
        setShouldShowNavbar(true) // Scrolling up, show navbar
      } else {
        setShouldShowNavbar(false) // Scrolling down, hide navbar
      }
      setLastScrollPosition(currentScrollPosition)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      // Cleanup
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollPosition])
  return (
    <div
      className={`${
        shouldShowNavbar ? 'scroll-progress-container' : 'scroll-progress-true'
      } `}
    >
      <div
        className="scroll-progress-bar"
        style={{ width: `${scroll * 100}%` }} // multiply by 100 to convert to percentage
      ></div>
    </div>
  )
}

export default ScrollProgressBar

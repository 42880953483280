import React, { Fragment } from 'react'
import ContactusStyles from './Contactus.Styles.module.css'
import ContactusFormImage from '../../assets/ContactsUsForm.svg'
import Form from './components/FormContainer/Form'
import SubContainer from './components/SubContainer/SubContainer'
import contactUsBg from '../../assets/ContactUsMainbkgrnd.png'
import ContactUsImage from '../../assets/ontactMainImg.svg'
const cs = ContactusStyles
const ContactUs = () => {
  return (
    <div id="contactus">
      <h3 className="text-black p-6 font-bold ">Contacts us</h3>
      <div className="flex gap-10 px-5 py-2 xl:flex xl:gap:10 justify-center items-center ">
        <img
          src={ContactUsImage}
          alt=""
          className="hidden lg:block h-[90vh] w-[40%] max-h-[600px]"
        />
        <div className="w-[100%] text-black flex flex-col justify-center items-center ">
          <div className=" flex justify-start text-3xl py-3">
            Contact Information
          </div>
          <Form />
        </div>
      </div>
    </div>
  )
}

export default ContactUs

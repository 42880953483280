import React from 'react'
import Home from '../../components/Home/Home'
import Location from '../../components/Location/Location'
import Footer from '../../components/Footer/Footer'
import Service from '../../components/Services/Services'
import Gallery from '../../components/Gallery/Gallery'
import ContactUs from '../../components/Contactus/ContactUs'
import Aboutus from '../../components/AboutUs/Aboutus'
import ScrollProgressBar from '../../components/progersiveScrollBar/ScrollProgressBar'
const LandingPage = () => {
  return (
    <div>
      <Home />
      <Service />
      <Aboutus />
      <Gallery />
      <ContactUs />
      <Location />
      <Footer />
    </div>
  )
}

export default LandingPage

import React from 'react'
import HeaderData from './HeadrerData'
const Header: React.FC = () => {
  return (
    <div className="bg-gray-900 w-full h-10  hidden md:flex justify-end">
      <div className="flex gap-10">
        {/* <div className="flex gap-6">
          {HeaderData?.map((data) => {
            return <img src={data.image} className="w-6" />
          })}
        </div> */}
        <div className="flex justify-center items-center px-20">
          <div className="flex justify-center items-center gap-2">
            <img
              src="https://c.animaapp.com/0ZfEe3OS/img/phone-1.svg"
              alt="Phone"
              className="w-5 h-5"
            />
            <div className="font-medium text-white text-lg">+91 9448052826</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

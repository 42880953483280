import React, { InputHTMLAttributes } from 'react'

interface IFormInputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string
  labelstyle?: any
  inputstyle?: any
  inputType?: any
}

const FormInput = React.forwardRef<HTMLInputElement, IFormInputProps>(
  ({ label, inputType, id, ...props }, ref) => {
    return (
      <div className="">
        <div className="flex flex-col gap-2 justify-center relative">
          {label && (
            <label className={`${props.labelstyle} `} htmlFor={id}>
              {label}
            </label>
          )}
          {inputType === 'input' ? (
            <input
              id={id}
              ref={ref}
              {...props}
              className={` p-3 py-4 rounded-[10px] bg-[rgba(240,240,240,0.94)] ${props.inputstyle} outline-none `}
              type={props?.type}
            />
          ) : (
            <textarea
              id={id}
              required
              placeholder="Message Here"
              className=" flex-shrink-0 px-6 py-7 rounded-[10px] 
              bg-[rgba(240,240,240,0.94)] outline-none"
              {...props}
            ></textarea>
          )}
        </div>
      </div>
    )
  },
)

export default FormInput

import GlassWork from '../../../assets/GlassWork.svg';
import AluminiumPartion from '../../../assets/AluminiuDoor.svg';
import CeilingWorks from '../../../assets/CeilingWall.svg';
import GlassAndStorage from '../../../assets/GlassStorage.svg';
import InteriorWoodWork from '../../../assets/InteriorWoodWork.svg';
import Mosquito from '../../../assets/MosquiotFrameShutter.svg';
import FalseCeiling from '../../../assets/PopFalseCeiling.svg';
import TvCabinet from '../../../assets/TvCabinet.svg';
import Mirror from '../../../assets/MirrorandDoor.svg';
import Upvs from '../../../assets/Upvs windows.svg';
import Elevation from '../../../assets/ElevationAcp.svg';
import AluminumProfiles from '../../../assets/AluminiumProfiles.svg';
import BathroomPvs from '../../../assets/bathroomPvs.svg';
import ACP from '../../../assets/AcpPPc.svg';
import AluminiumFittings from '../../../assets/AluminiumFIttings.svg';

const ServiceData = [
  {
    id: "1",
    image: GlassWork,
    text: "Glass Work",
    path: "GlassToughned",
    rowspan: 1,
  },
  {
    id: "2",
    image: AluminiumPartion,
    text: "Aluminium Door",
    path: "AluminiumWorks",
    rowspan: 1,
  },
  {
    id: "3",
    image: CeilingWorks,
    text: "Ceiling Works",
    path: "Ceiling",
    rowspan: 1,
  },
  {
    id: "4",
    image: GlassAndStorage,
    text: "Glass Storages",
    path: "GlassWorks",
    rowspan: 1,
  },
  {
    id: "5",
    image: InteriorWoodWork,
    text: "Interior Wood Work",
    path: "Interior",
    rowspan: 1,
  },
  {
    id: "6",
    image: Mosquito,
    text: "Mosquito Frame Shutter",
    path: "Mosquito",
    rowspan: 1,
  },
  {
    id: "7",
    image: FalseCeiling,
    text: "Pop False Ceilings",
    path: "PopFalseCeiling",
    rowspan: 2,
  },
  {
    id: "8",
    image: TvCabinet,
    text: "TV Cabinet",
    path: "TVCabinet",
    rowspan: 1,
  },
  {
    id: "9",
    image: Mirror,
    text: "Mirror and Door",
    path: "../",
    rowspan: 1,
  },
  {
    id: "10",
    image: Upvs,
    text: "UPVC Windows",
    path: "../",
    rowspan: 1,
  },
  {
    id: "11",
    image: Elevation,
    text: "Elevation ACP",
    path: "../",
    rowspan: 1,
  },
  {
    id: "12",
    image: AluminumProfiles,
    text: "Aluminium Profiles",
    path: "../",
    rowspan: 1,
  },
  {
    id: "13",
    image: BathroomPvs,
    text: "Bathroom PVCs",
    path: "BathroomPVC",
    rowspan: 1,
  },
  {
    id: "14",
    image: ACP,
    text: "ACP PPC",
    path: "../",
    rowspan: 1,
  },
  {
    id: "15",
    image: AluminiumFittings,
    text: "Aluminium Fittings",
    path: "../",
    rowspan: 1,
  },
  // Add more objects after "Aluminium Fittings" here
];

// Add more objects as needed

export default ServiceData;

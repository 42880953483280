import React from 'react'
import WhiteHouse from '../../assets/whiteHouseComp.svg'
import GroupNestPic from '../../assets/groupNestImage.svg'
const HomeSubComp = () => {
  return (
    <div className="flex flex-col gap-5 mt-10 ">
      <div className="text-xl font-normal text-gray-800 leading-normal">
        27+ Years of Experience in Industry
      </div>
      <div className="flex">
        <img src={WhiteHouse} className="w-1/2" />
        <img src={GroupNestPic} className="w-1/2" />
      </div>
    </div>
  )
}

export default HomeSubComp

const photos = [
    {
      "folder_name": "aluminiumworks",
      "files": [
        {
          "id": "IMG-20231009-WA0151",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0151.jpg"
        },
        {
          "id": "IMG-20231009-WA0152",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0152.jpg"
        },
        {
          "id": "IMG-20231009-WA0153",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0153.jpg"
        },
        {
          "id": "IMG-20231009-WA0154",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0154.jpg"
        },
        {
          "id": "IMG-20231009-WA0155",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0155.jpg"
        },
        {
          "id": "IMG-20231009-WA0156",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0156.jpg"
        },
        {
          "id": "IMG-20231009-WA0157",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0157.jpg"
        },
        {
          "id": "IMG-20231009-WA0158",
          "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/IMG-20231009-WA0158.jpg"
        },
       
      ]
    },
    {
      "folder_name": "bathroompvc",
      "files": [
      
        {
          "id": "3S6fIDltqIZNTavbhA6hYCoUhFdvSb2hW0JVQzt_6PA=_plaintext_638325933077038644",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/3S6fIDltqIZNTavbhA6hYCoUhFdvSb2hW0JVQzt_6PA=_plaintext_638325933077038644.jpg"
        },
        {
          "id": "GmUFNwoDiV0-iOErWl7Agfo4vAIX2wsbqNDmOktpqUg=_plaintext_638325933048895263",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/GmUFNwoDiV0-iOErWl7Agfo4vAIX2wsbqNDmOktpqUg=_plaintext_638325933048895263.jpg"
        },
        // {
        //   "id": "IMG-20231011-WA0004",
        //   "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/IMG-20231011-WA0004.jpg"
        // },
        // {
        //   "id": "IMG-20231011-WA0005",
        //   "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/IMG-20231011-WA0005.jpg"
        // },
        // {
        //   "id": "IMG-20231011-WA0006",
        //   "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/IMG-20231011-WA0006.jpg"
        // },
    
        {
          "id": "MUZ-TEFXaBYzVdu-BRFpR8LERYHB6Zqkh-U5gc4rgOM=_plaintext_638325933076525769",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/MUZ-TEFXaBYzVdu-BRFpR8LERYHB6Zqkh-U5gc4rgOM=_plaintext_638325933076525769.jpg"
        },
        {
          "id": "RUiSqy9Z4bA4PHO0dJ-NZqKlibnjQ1Wg_I3IJz2tGTo=_plaintext_638325933076795776",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/RUiSqy9Z4bA4PHO0dJ-NZqKlibnjQ1Wg_I3IJz2tGTo=_plaintext_638325933076795776.jpg"
        },
        {
          "id": "bP6bLr8aIMJovOpiuPusZslLqQpDIyw4kTcCOLI8tGk=_plaintext_638325933077650264",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/bP6bLr8aIMJovOpiuPusZslLqQpDIyw4kTcCOLI8tGk=_plaintext_638325933077650264.jpg"
        },
        {
          "id": "eKAwtcpc7HMz-oOAoXLxM9FAwKgpX8RbcaA-ZnIk1RQ=_plaintext_638325933077705026",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/eKAwtcpc7HMz-oOAoXLxM9FAwKgpX8RbcaA-ZnIk1RQ=_plaintext_638325933077705026.jpg"
        },
        {
          "id": "h8rJGhx0mG8kpADfraPMSA5ngD4gK79xLpZoKNyDSQQ=_plaintext_638325933052843725",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/h8rJGhx0mG8kpADfraPMSA5ngD4gK79xLpZoKNyDSQQ=_plaintext_638325933052843725.jpg"
        },
        {
          "id": "yfyxSMhfgKByj77U4VvEOX7pHhru78u0ZjOnansOxgk=_plaintext_638325933076669999",
          "url": "https://svaluminium.s3.amazonaws.com/bathroompvc/yfyxSMhfgKByj77U4VvEOX7pHhru78u0ZjOnansOxgk=_plaintext_638325933076669999.jpg"
        }
      ]
    },
    {
      "folder_name": "ceiling",
      "files": [
        {
          "id": "IMG-20231009-WA0128",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0128.jpg"
        },
        {
          "id": "IMG-20231009-WA0129",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0129.jpg"
        },
        {
          "id": "IMG-20231009-WA0130",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0130.jpg"
        },
        {
          "id": "IMG-20231009-WA0131",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0131.jpg"
        },
        {
          "id": "IMG-20231009-WA0132",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0132.jpg"
        },
        {
          "id": "IMG-20231009-WA0133",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0133.jpg"
        },
        {
          "id": "IMG-20231009-WA0134",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0134.jpg"
        },
        {
          "id": "IMG-20231009-WA0135",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0135.jpg"
        },
        {
          "id": "IMG-20231009-WA0136",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0136.jpg"
        },
        {
          "id": "IMG-20231009-WA0137",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0137.jpg"
        },
        {
          "id": "IMG-20231009-WA0138",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0138.jpg"
        },
        {
          "id": "IMG-20231009-WA0139",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0139.jpg"
        },
        {
          "id": "IMG-20231009-WA0140",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0140.jpg"
        },
        {
          "id": "IMG-20231009-WA0141",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0141.jpg"
        },
        {
          "id": "IMG-20231009-WA0142",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0142.jpg"
        },
        {
          "id": "IMG-20231009-WA0143",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0143.jpg"
        },
        {
          "id": "IMG-20231009-WA0144",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0144.jpg"
        },
        {
          "id": "IMG-20231009-WA0145",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0145.jpg"
        },
        {
          "id": "IMG-20231009-WA0146",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0146.jpg"
        },
        {
          "id": "IMG-20231009-WA0147",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0147.jpg"
        },
        {
          "id": "IMG-20231009-WA0148",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0148.jpg"
        },
        {
          "id": "IMG-20231009-WA0149",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0149.jpg"
        },
        {
          "id": "IMG-20231009-WA0150",
          "url": "https://svaluminium.s3.amazonaws.com/ceiling/IMG-20231009-WA0150.jpg"
        }
      ]
    },
    {
      "folder_name": "glasstoughned",
      "files": [
       
        {
          "id": "0VF2ba6znv_HTnQes30MCTHkwidyXafEcwltVCCTUYk=_plaintext_638324260756633722",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/0VF2ba6znv_HTnQes30MCTHkwidyXafEcwltVCCTUYk=_plaintext_638324260756633722.jpg"
        },
        {
          "id": "1J8N562FXPZ8SxXXLzv7drm8HfXIljL5qL4HwadjOco=_plaintext_638324260780501136",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/1J8N562FXPZ8SxXXLzv7drm8HfXIljL5qL4HwadjOco=_plaintext_638324260780501136.jpg"
        },
        {
          "id": "1SWSriabFlYjGxl9wJiZhQyhRhJ88-ePJlMT_Dho1bo=_plaintext_638324260784497338",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/1SWSriabFlYjGxl9wJiZhQyhRhJ88-ePJlMT_Dho1bo=_plaintext_638324260784497338.jpg"
        },
        {
          "id": "2ofMx4u_MU-nWUYC5My3gzemDbIiSBuJPy8NQkNnnQw=_plaintext_638324260754633404",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/2ofMx4u_MU-nWUYC5My3gzemDbIiSBuJPy8NQkNnnQw=_plaintext_638324260754633404.jpg"
        },
     
        {
          "id": "4KWd35FXbwAHWQFPneK-t_YV8Z5pNeWE8o0AHdsBXWM=_plaintext_638324260733927502",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/4KWd35FXbwAHWQFPneK-t_YV8Z5pNeWE8o0AHdsBXWM=_plaintext_638324260733927502.jpg"
        },
        {
          "id": "5jCjdfy8mBBeceDPlqiNTH1LtRwxn8wxoCkZSvLNtsk=_plaintext_638324260740276754",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/5jCjdfy8mBBeceDPlqiNTH1LtRwxn8wxoCkZSvLNtsk=_plaintext_638324260740276754.jpg"
        },
        {
          "id": "6ml3dGLKPrhCCWLOayuSInjzPXj38nRa5Zfoqb_tz5A=_plaintext_638324260787545422",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/6ml3dGLKPrhCCWLOayuSInjzPXj38nRa5Zfoqb_tz5A=_plaintext_638324260787545422.jpg"
        },
        {
          "id": "BZ1_CuENOhqv7hZQ2nZwBw8nEyMsQtWzAVMmeCJnqMU=_plaintext_638324260761025046",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/BZ1_CuENOhqv7hZQ2nZwBw8nEyMsQtWzAVMmeCJnqMU=_plaintext_638324260761025046.jpg"
        },
        {
          "id": "Ei5z3S7VwqpU7W8jrboRECa6NZgOzaqTrk4o3T_3a60=_plaintext_638324260768989984",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/Ei5z3S7VwqpU7W8jrboRECa6NZgOzaqTrk4o3T_3a60=_plaintext_638324260768989984.jpg"
        },
    
        {
          "id": "J83BxH-oLdyii82PI1AAKRkfOs2lC6-JLcAsKQie8CU=_plaintext_638324260723892290",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/J83BxH-oLdyii82PI1AAKRkfOs2lC6-JLcAsKQie8CU=_plaintext_638324260723892290.jpg"
        },
        {
          "id": "RLonR135dw2AHzv6DEAOzC6HE3hb-yV4kEkPgpBAciY=_plaintext_638324260743437312",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/RLonR135dw2AHzv6DEAOzC6HE3hb-yV4kEkPgpBAciY=_plaintext_638324260743437312.jpg"
        },
        {
          "id": "Ss2WsCIzrl4q26wigPIyUjXcD8TkqrP5Qcld4S2r7Nk=_plaintext_638324260797681231",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/Ss2WsCIzrl4q26wigPIyUjXcD8TkqrP5Qcld4S2r7Nk=_plaintext_638324260797681231.jpg"
        },
        {
          "id": "TlEliwFk6hTK16wa4Hrvt5jlWHLyqQWzSZl2X9QSU10=_plaintext_638324260726959944",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/TlEliwFk6hTK16wa4Hrvt5jlWHLyqQWzSZl2X9QSU10=_plaintext_638324260726959944.jpg"
        },
        {
          "id": "UA35FpiiDsi35xyh7lHXhq85K5AADHdpL6BT5A8fDGk=_plaintext_638324260737126298",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/UA35FpiiDsi35xyh7lHXhq85K5AADHdpL6BT5A8fDGk=_plaintext_638324260737126298.jpg"
        },
        {
          "id": "bwghn2e6W12TkCHrr4BFve7p0caTE2gQdS-9GWVHl6g=_plaintext_638324260800068210",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/bwghn2e6W12TkCHrr4BFve7p0caTE2gQdS-9GWVHl6g=_plaintext_638324260800068210.jpg"
        },
        {
          "id": "eb91GWIHtkhE_LAN61VoM_dHZ1oN5xReCYT9CG3gdTE=_plaintext_638324260790334731",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/eb91GWIHtkhE_LAN61VoM_dHZ1oN5xReCYT9CG3gdTE=_plaintext_638324260790334731.jpg"
        },
        {
          "id": "guCcz4DJnIg33ceB2tDM2sq0-LBaNos3DvSe7-yZnx8=_plaintext_638324260762936342",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/guCcz4DJnIg33ceB2tDM2sq0-LBaNos3DvSe7-yZnx8=_plaintext_638324260762936342.jpg"
        },
        {
          "id": "iUo1QpNhIqpglMjWGnLSaF2lOFjjVahs-c-klqgpBTg=_plaintext_638324260767031107",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/iUo1QpNhIqpglMjWGnLSaF2lOFjjVahs-c-klqgpBTg=_plaintext_638324260767031107.jpg"
        },
      
        {
          "id": "mD2WHlh9khl_fCoFNoMZQ-LO90fTVdkkpwRMHKhQVf0=_plaintext_638324260720445488",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/mD2WHlh9khl_fCoFNoMZQ-LO90fTVdkkpwRMHKhQVf0=_plaintext_638324260720445488.jpg"
        },
        {
          "id": "vaAWHFP4SiH3_u0rth7R9GTTKGCRuI9hu6hCjy1P2R8=_plaintext_638324260793192515",
          "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/vaAWHFP4SiH3_u0rth7R9GTTKGCRuI9hu6hCjy1P2R8=_plaintext_638324260793192515.jpg"
        }
      ]
    },
    {
      "folder_name": "glassworks",
      "files": [
        {
          "id": "IMG-20231009-WA0076",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0076.jpg"
        },
        {
          "id": "IMG-20231009-WA0077",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0077.jpg"
        },
        {
          "id": "IMG-20231009-WA0078",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0078.jpg"
        },
        {
          "id": "IMG-20231009-WA0079",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0079.jpg"
        },
        {
          "id": "IMG-20231009-WA0080",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0080.jpg"
        },
        {
          "id": "IMG-20231009-WA0081",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0081.jpg"
        },
        {
          "id": "IMG-20231009-WA0082",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0082.jpg"
        },
        {
          "id": "IMG-20231009-WA0083",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0083.jpg"
        },
        {
          "id": "IMG-20231009-WA0084",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0084.jpg"
        },
        {
          "id": "IMG-20231009-WA0085",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0085.jpg"
        },
        {
          "id": "IMG-20231009-WA0086",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0086.jpg"
        },
        {
          "id": "IMG-20231009-WA0087",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0087.jpg"
        },
        {
          "id": "IMG-20231009-WA0088",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0088.jpg"
        },
        {
          "id": "IMG-20231009-WA0089",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0089.jpg"
        },
        {
          "id": "IMG-20231009-WA0090",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0090.jpg"
        },
        {
          "id": "IMG-20231009-WA0091",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0091.jpg"
        },
        {
          "id": "IMG-20231009-WA0092",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0092.jpg"
        },
        {
          "id": "IMG-20231009-WA0093",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0093.jpg"
        },
        {
          "id": "IMG-20231009-WA0094",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0094.jpg"
        },
        {
          "id": "IMG-20231009-WA0095",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0095.jpg"
        },
        {
          "id": "IMG-20231009-WA0096",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0096.jpg"
        },
        {
          "id": "IMG-20231009-WA0097",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0097.jpg"
        },
        {
          "id": "IMG-20231009-WA0098",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0098.jpg"
        },
        {
          "id": "IMG-20231009-WA0099",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0099.jpg"
        },
        {
          "id": "IMG-20231009-WA0100",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0100.jpg"
        },
        {
          "id": "IMG-20231009-WA0101",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0101.jpg"
        },
        {
          "id": "IMG-20231009-WA0102",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0102.jpg"
        },
        {
          "id": "IMG-20231009-WA0103",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0103.jpg"
        },
        {
          "id": "IMG-20231009-WA0104",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0104.jpg"
        },
        {
          "id": "IMG-20231009-WA0105",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0105.jpg"
        },
        {
          "id": "IMG-20231009-WA0106",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0106.jpg"
        },
        {
          "id": "IMG-20231009-WA0107",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0107.jpg"
        },
        {
          "id": "IMG-20231009-WA0108",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0108.jpg"
        },
        {
          "id": "IMG-20231009-WA0109",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0109.jpg"
        },
        {
          "id": "IMG-20231009-WA0110",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0110.jpg"
        },
        {
          "id": "IMG-20231009-WA0111",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0111.jpg"
        },
        {
          "id": "IMG-20231009-WA0112",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0112.jpg"
        },
        {
          "id": "IMG-20231009-WA0113",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0113.jpg"
        },
        {
          "id": "IMG-20231009-WA0114",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0114.jpg"
        },
        {
          "id": "IMG-20231009-WA0115",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0115.jpg"
        },
        {
          "id": "IMG-20231009-WA0116",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0116.jpg"
        },
        {
          "id": "IMG-20231009-WA0117",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0117.jpg"
        },
        {
          "id": "IMG-20231009-WA0118",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0118.jpg"
        },
        {
          "id": "IMG-20231009-WA0119",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0119.jpg"
        },
        {
          "id": "IMG-20231009-WA0120",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0120.jpg"
        },
        {
          "id": "IMG-20231009-WA0121",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0121.jpg"
        },
        {
          "id": "IMG-20231009-WA0122",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0122.jpg"
        },
        {
          "id": "IMG-20231009-WA0123",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0123.jpg"
        },
        {
          "id": "IMG-20231009-WA0124",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0124.jpg"
        },
        {
          "id": "IMG-20231009-WA0125",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0125.jpg"
        },
        {
          "id": "IMG-20231009-WA0126",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0126.jpg"
        },
        {
          "id": "IMG-20231009-WA0127",
          "url": "https://svaluminium.s3.amazonaws.com/glassworks/IMG-20231009-WA0127.jpg"
        }
      ]
    },
    {
      "folder_name": "interior",
      "files": [
        {
          "id": "IMG-20231009-WA0021",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0021.jpg"
        },
        {
          "id": "IMG-20231009-WA0022",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0022.jpg"
        },
        {
          "id": "IMG-20231009-WA0023",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0023.jpg"
        },
        {
          "id": "IMG-20231009-WA0024",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0024.jpg"
        },
        {
          "id": "IMG-20231009-WA0025",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0025.jpg"
        },
        {
          "id": "IMG-20231009-WA0026",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0026.jpg"
        },
        {
          "id": "IMG-20231009-WA0027",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0027.jpg"
        },
        {
          "id": "IMG-20231009-WA0028",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0028.jpg"
        },
        {
          "id": "IMG-20231009-WA0029",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0029.jpg"
        },
        {
          "id": "IMG-20231009-WA0030",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0030.jpg"
        },
        {
          "id": "IMG-20231009-WA0031",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0031.jpg"
        },
        {
          "id": "IMG-20231009-WA0032",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0032.jpg"
        },
        {
          "id": "IMG-20231009-WA0033",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0033.jpg"
        },
        {
          "id": "IMG-20231009-WA0034",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0034.jpg"
        },
        {
          "id": "IMG-20231009-WA0035",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0035.jpg"
        },
        {
          "id": "IMG-20231009-WA0036",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0036.jpg"
        },
        {
          "id": "IMG-20231009-WA0037",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0037.jpg"
        },
        {
          "id": "IMG-20231009-WA0038",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0038.jpg"
        },
        {
          "id": "IMG-20231009-WA0039",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0039.jpg"
        },
        {
          "id": "IMG-20231009-WA0040",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0040.jpg"
        },
        {
          "id": "IMG-20231009-WA0041",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0041.jpg"
        },
        {
          "id": "IMG-20231009-WA0042",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0042.jpg"
        },
        {
          "id": "IMG-20231009-WA0043",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0043.jpg"
        },
        {
          "id": "IMG-20231009-WA0044",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0044.jpg"
        },
        {
          "id": "IMG-20231009-WA0045",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0045.jpg"
        },
        {
          "id": "IMG-20231009-WA0046",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0046.jpg"
        },
        {
          "id": "IMG-20231009-WA0047",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0047.jpg"
        },
        {
          "id": "IMG-20231009-WA0048",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0048.jpg"
        },
        {
          "id": "IMG-20231009-WA0049",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0049.jpg"
        },
        {
          "id": "IMG-20231009-WA0050",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0050.jpg"
        },
        {
          "id": "IMG-20231009-WA0051",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0051.jpg"
        },
        {
          "id": "IMG-20231009-WA0052",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0052.jpg"
        },
        {
          "id": "IMG-20231009-WA0053",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0053.jpg"
        },
        {
          "id": "IMG-20231009-WA0054",
          "url": "https://svaluminium.s3.amazonaws.com/interior/IMG-20231009-WA0054.jpg"
        }
      ]
    },
    {
      "folder_name": "mosquito",
      "files": [
        {
          "id": "IMG-20231009-WA0075",
          "url": "https://svaluminium.s3.amazonaws.com/mosquito/IMG-20231009-WA0075.jpg"
        },
       
      ]
    },
    {
      "folder_name": "popfalseceiling",
      "files": [
       
        {
          "id": "IMG-20231009-WA0055",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0055.jpg"
        },
        {
          "id": "IMG-20231009-WA0056",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0056.jpg"
        },
        {
          "id": "IMG-20231009-WA0057",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0057.jpg"
        },
        {
          "id": "IMG-20231009-WA0058",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0058.jpg"
        },
        {
          "id": "IMG-20231009-WA0059",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0059.jpg"
        },
        {
          "id": "IMG-20231009-WA0060",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0060.jpg"
        },
        {
          "id": "IMG-20231009-WA0061",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0061.jpg"
        },
        {
          "id": "IMG-20231009-WA0062",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0062.jpg"
        },
        {
          "id": "IMG-20231009-WA0063",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0063.jpg"
        },
        {
          "id": "IMG-20231009-WA0064",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0064.jpg"
        },
        {
          "id": "IMG-20231009-WA0065",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0065.jpg"
        },
        {
          "id": "IMG-20231009-WA0066",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0066.jpg"
        },
        {
          "id": "IMG-20231009-WA0067",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0067.jpg"
        },
        {
          "id": "IMG-20231009-WA0068",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0068.jpg"
        },
        {
          "id": "IMG-20231009-WA0069",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0069.jpg"
        },
        {
          "id": "IMG-20231009-WA0070",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0070.jpg"
        },
        {
          "id": "IMG-20231009-WA0071",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0071.jpg"
        },
        {
          "id": "IMG-20231009-WA0072",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0072.jpg"
        },
        {
          "id": "IMG-20231009-WA0073",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0073.jpg"
        },
        {
          "id": "IMG-20231009-WA0074",
          "url": "https://svaluminium.s3.amazonaws.com/popfalseceiling/IMG-20231009-WA0074.jpg"
        }
      ]
    },
    {
      "folder_name": "tvcabinet",
      "files": [
      
        // {
        //   "id": "-PJMGaIRVO8d9eurGhi2qSKcQD33Ok5yexsoeSgDeTY=_plaintext_638325105300372747",
        //   "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/-PJMGaIRVO8d9eurGhi2qSKcQD33Ok5yexsoeSgDeTY=_plaintext_638325105300372747.jpg"
        // },
        {
          "id": "1HUhMZPI9bUjRVK59ENU59aB79_aUrqIM2IAQi4y7ys=_plaintext_638325105324791375",
          "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/1HUhMZPI9bUjRVK59ENU59aB79_aUrqIM2IAQi4y7ys=_plaintext_638325105324791375.jpg"
        },
        {
          "id": "7z3WHFoAsOq7na1vzY6ga_b2I9DOR6UAcBydBTeN3D8=_plaintext_638325105327927957",
          "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/7z3WHFoAsOq7na1vzY6ga_b2I9DOR6UAcBydBTeN3D8=_plaintext_638325105327927957.jpg"
        },
        // {
        //   "id": "9faVQRea7RlL-BIkjM3o3HL5SIWKf-5UY5GAmq2Pkbk=_plaintext_638325105303462328",
        //   "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/9faVQRea7RlL-BIkjM3o3HL5SIWKf-5UY5GAmq2Pkbk=_plaintext_638325105303462328.jpg"
        // },
        {
          "id": "A_TfCMTXobBp45udOOeiHXLOA1CPxAvowyIP5ezfbf4=_plaintext_638325105317917408",
          "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/A_TfCMTXobBp45udOOeiHXLOA1CPxAvowyIP5ezfbf4=_plaintext_638325105317917408.jpg"
        },
        {
          "id": "FMas-WVhc4iezX2uI5pn7mDlKTgAtNEqt4nxXRvpuXM=_plaintext_638325105310575739",
          "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/FMas-WVhc4iezX2uI5pn7mDlKTgAtNEqt4nxXRvpuXM=_plaintext_638325105310575739.jpg"
        },
        // {
        //   "id": "RdH1eTLGN6fWApIs0fk3X0K2Ul3im2acAZt8d5UZLW4=_plaintext_638325105314493227",
        //   "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/RdH1eTLGN6fWApIs0fk3X0K2Ul3im2acAZt8d5UZLW4=_plaintext_638325105314493227.jpg"
        // },
        // {
        //   "id": "VLlJmVkCGKfG931dUFhU5ytPqoVSc3gWwavIq1mLuVA=_plaintext_638325105305435219",
        //   "url": "https://svaluminium.s3.amazonaws.com/tvcabinet/VLlJmVkCGKfG931dUFhU5ytPqoVSc3gWwavIq1mLuVA=_plaintext_638325105305435219.jpg"
        // },
     
      ]
    }
  ]

  export default photos
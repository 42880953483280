import React, { Fragment } from 'react'
import photos from '../../Dataset/photos'
import { useParams } from 'react-router-dom'
import videos from '../../Dataset/videos'
const Gallery = () => {
  const params = useParams()
  const filterArray = photos.filter((data) => {
    return data.folder_name == params.galleryName?.toLowerCase()
  })

  // console.log(filterArray?.[0]?.files, 'seee hereeee')
  const filterVideos = videos.filter((data) => {
    return data.file_name == params.galleryName?.toLowerCase()
  })
  // console.log(filterVideos?.[0]?.files, 'videos')
  return (
    <>
      <h1 style={{ color: 'black', padding: '1rem', fontWeight: 'bold' }}>
        {params.galleryName} Images
      </h1>
      <div className="grid grid-cols-3 gap-6 pt-6 px-1 overflow-x-hidden">
        {filterArray?.[0]?.files?.map((data: any) => {
          return (
            <Fragment key={data?.id}>
              <div key={data?.id}>
                <img
                  src={data?.url}
                  // className="w-[250px] h-[200px] md:w-[500px] md:h-[350px] lg:w-[800px] lg:h-[400px] rounded-md object-contain pb-3"
                  className="w-[250px] h-[200px] md:w-[500px] md:h-[350px] lg:w-[800px] lg:h-[400px] object-cover
                  "
                />
              </div>
            </Fragment>
          )
        })}
      </div>
      {filterVideos.length > 0 ? (
        <p style={{ color: 'black', padding: '1rem', fontWeight: 'bold' }}>
          Videos
        </p>
      ) : null}

      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 pt-3 pb-2 ">
        {filterVideos?.[0]?.files?.map((data) => (
          <div key={data.id} className="mb-4">
            {data.url.includes('.mp4') ? (
              <video
                controls
                className="w-[250px] h-[200px] md:w-[500px] md:h-[350px] lg:w-[750px] lg:h-[400px] m-0"
              >
                <source src={data.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
        ))}
      </div>
    </>
  )
}

export default Gallery

// Importing images
import Gallery1 from "../../../assets/gallery1.svg";
import Gallery2 from "../../../assets/gallery2.svg";
import Gallery3 from "../../../assets/gallery3.svg";
import Gallery4 from "../../../assets/Gallery4.svg";
import Gallery5 from "../../../assets/Gallery5.svg";
import Gallery6 from "../../../assets/Gallery6.svg";
import Gallery7 from "../../../assets/Gallery7.svg";
import Gallery8 from "../../../assets/Gallery8.svg";
import Gallery9 from "../../../assets/gallery9.svg";
import Gallery10 from "../../../assets/gallery10.svg";
import Gallery11 from "../../../assets/gallery11.svg";
import Gallery12 from "../../../assets/Gallery12.svg";

// Organizing imported images into an array of objects
const galleries = [
  { id: 1, image: Gallery1 },
  { id: 2, image: Gallery2 },
  { id: 3, image: Gallery3 },
  { id: 4, image: Gallery4 },
  { id: 5, image: Gallery5 },
  { id: 6, image: Gallery6 },
  { id: 7, image: Gallery7 },
  { id: 8, image: Gallery8 },
  { id: 9, image: Gallery9 },
  { id: 10, image: Gallery10 },
  { id: 11, image: Gallery11 },
  { id: 12, image: Gallery12 }
];

export default galleries;

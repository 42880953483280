import React from 'react'
import NavBar from './components/Navbar/Navbar'
import Header from './components/Header/Header'
import { BrowserRouter } from 'react-router-dom'
import Home from './components/Home/Home'
import Route from './Router/Router'
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Route />
    </BrowserRouter>
  )
}

export default App

import React from 'react'
import AboutStyles from './aboutus.styles.module.css'
import AboutUsMain from '../../assets/AboutUsMainImage.svg'
import Sub from '../../assets/AboutUsSubImage.svg'
const as = AboutStyles
const Aboutus = () => {
  return (
    <div id="about">
      <div className="text-black font-bold pt-8 p-6 -mb-8">About Us</div>
      <div
        className={` flex flex-col md:flex-col lg:flex-row sm:flex-row  justify-between sm:gap-3 items-center`}
      >
        <div className="text-sm sm:text-base text-black lg:flex lg:w-[50%] p-10 text-justify">
          Founded in 1997 by our esteemed owner, S. Manickam, SV Aluminium Doors
          and Windows has been a steadfast presence in the industry,serving the
          community of Pavoorchathram, Tenkasi, and its neighbouring regions.
          Our journey began with a simple vision - to bring the modernized
          elegance and functionality of metropolitan areas like Bangalore to our
          local surroundings. At SV Aluminium, our vision is clear to
          revolutionize the way our local regions experience all types of
          interior works. We are committed to infusing modernity into every
          project we undertake while maintaining affordability, premium quality,
          and efficient delivery. We understand that time is of the essence, and
          our experienced team of professionals is dedicated to delivering
          exceptional results within short timeframes.
        </div>
        <div
          className={`flex justify-center sm:pl-5 m-13 sm:-m-0 items-center relative`}
        >
          <img
            src={AboutUsMain}
            alt=""
            className={`h-[60%]  sm:w-[70%] ${as.main_image} `}
          />
          <img
            src={Sub}
            alt=""
            className=" h-[60%]  absolute -bottom-10 -left-10 sm:-left-50"
          />
        </div>
      </div>
    </div>
  )
}

export default Aboutus

import React, { MouseEvent, ReactNode, HTMLProps } from 'react'
import { Link } from 'react-router-dom'

interface CustomButtonProps extends HTMLProps<HTMLButtonElement> {
  children?: ReactNode
  to?: any
  cursorPointer?: boolean
  text?: string
  type?: 'button' | 'submit' | 'reset'
  twMergeClx?: string
  className?: string
}

const Button: React.FC<CustomButtonProps> = ({
  children,
  onClick,
  to,
  cursorPointer = true,
  className = '',
  ...restProps
}) => {
  const buttonProps = {
    onClick,
    style: { cursor: cursorPointer ? 'pointer' : 'default' },
    ...restProps,
  }

  const buttonBaseStyle =
    'flex items-center justify-center w-40 h-[40px] bg-yellow-500 rounded-[8px] flex-shrink-0'

  return to ? (
    <Link to={to} className={`${buttonBaseStyle} ${className}`}>
      <button {...buttonProps} className={`${buttonBaseStyle} ${className}`}>
        {children}
      </button>
    </Link>
  ) : (
    <button {...buttonProps} className={`${buttonBaseStyle} ${className}`}>
      {children}
    </button>
  )
}

export default Button

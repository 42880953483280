import React, { useState } from 'react'
import navbarData from './NavbarData.json'
import SvAluminiumLogo from '../../assets/svlog.svg'
import { Link } from 'react-scroll'
import { Link as Navigate, useLocation } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
interface type {
  NavbarOffSet: boolean
}
const Navbar = (Navbar: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const isHomePage = location.pathname === '/'
  return (
    <div className="bg-gray-800 px-4 py-2  text-white">
      <div className="px-3 flex justify-between items-center sm:px-20">
        <Link
          to="home"
          offset={-80}
          className="hover:text-blue-300"
          smooth
          duration={800}
          style={{ cursor: 'pointer' }}
        >
          <div>
            <img src={SvAluminiumLogo} className="w-20 h-30 " alt="home-log" />
          </div>
        </Link>

        <div
          className="hidden lg:flex gap-10 ml-4"
          style={{ cursor: 'pointer' }}
        >
          {isHomePage
            ? navbarData.map((item) => (
                <Link
                  key={item.id}
                  to={item.href}
                  offset={-item.offset}
                  className="hover:text-blue-300"
                  smooth
                  duration={800}
                  style={{ cursor: 'pointer' }}
                >
                  {item.name}
                </Link>
              ))
            : navbarData.map((item) => (
                <Navigate
                  key={item.id}
                  to={'/'}
                  className="hover:text-blue-300"
                  style={{ cursor: 'pointer' }}
                >
                  {item.name}
                </Navigate>
              ))}
        </div>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden p-2 rounded"
        >
          {isMenuOpen ? (
            <FaTimes size={30} className="text-white" />
          ) : (
            <FaBars size={30} className="text-white" />
          )}
        </button>
      </div>

      {isMenuOpen && (
        <ul
          className={`fixed top-0 right-0 z-40 pt-5 pl-5 w-60 h-screen bg-black text-white  ${
            isMenuOpen ? 'animate-slide-in-left' : ''
          } `}
        >
          <FaTimes
            size={30}
            className="text-white ml-36"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
          {navbarData.map(({ id, name, href }) => (
            <>
              <Link
                key={id}
                to={href}
                offset={-80}
                className="hover:text-blue-300"
                smooth
                duration={800}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <li key={id} className="text-xl  p-3 hover:text-gray-500">
                  {name}
                </li>
              </Link>
            </>
          ))}
          <div className="p-5">
            <hr className="text-bold"></hr>
          </div>
        </ul>
      )}
    </div>
  )
}

export default Navbar

import React from 'react'
import GalleryData from './Data/GalleryData'
const Gallery = () => {
  return (
    <div id="gallery">
      <h2 className="text-black font-bold pb-10">Gallery</h2>
      <div className=" pr-5 grid grid-cols-2 gap-16 sm:grid-cols-3 justify-center sm:gap-15 sm:justify-between items-center sm:px-24 px-5">
        {GalleryData.map((services: any) => {
          return (
            <div key={services.id}>
              <div className="min-w-[130px]  justify-between flex flex-col sm:justify-between gap-2 sm:max-w-[400px]">
                <img src={services.image} className="object-contain " />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Gallery

const videos = [{
file_name:"glasstoughned",
files:[

    {
        "id": "jb2vz4F_7tKkitshAeBBkKYSvoGkDYrGNmJo04TgH14=_plaintext_638324260750039692",
        "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/jb2vz4F_7tKkitshAeBBkKYSvoGkDYrGNmJo04TgH14=_plaintext_638324260750039692.mp4"
    }

  ,
    {
        "id": "HrkIIeLdJzg1lNcMpmBB1Z-Oxl_znT1YbCqvkzDrUG8=_plaintext_638324260748122859",
        "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/HrkIIeLdJzg1lNcMpmBB1Z-Oxl_znT1YbCqvkzDrUG8=_plaintext_638324260748122859.mp4"
      },
      {
        "id": "Iq55z6J7luXZB_kWHCU31j2n6xr5Ukmmafl3vFPVsHg=_plaintext_638324260806136570",
        "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/Iq55z6J7luXZB_kWHCU31j2n6xr5Ukmmafl3vFPVsHg=_plaintext_638324260806136570.mp4"
      },
      {
        "id": "3al7oIMR0nyC9oFTJNPd5cPk8lUOGMFOsCEMS2g0AyI=_plaintext_638324260730517331",
        "url": "https://svaluminium.s3.amazonaws.com/glasstoughned/3al7oIMR0nyC9oFTJNPd5cPk8lUOGMFOsCEMS2g0AyI=_plaintext_638324260730517331.mp4"
      },


]


},

{
    file_name:"glasswork",
    files:[

        {
            "id": "VID-20231009-WA0013",
            "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/VID-20231009-WA0013.mp4"
          },
          {
            "id": "VID-20231009-WA0014",
            "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/VID-20231009-WA0014.mp4"
          },
          {
            "id": "VID-20231009-WA0015",
            "url": "https://svaluminium.s3.amazonaws.com/aluminumworks/VID-20231009-WA0015.mp4"
          }

    ]

},
{
file_name:"mosquito",
files:[

  {
    "id": "VID-20231009-WA0005",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0005.mp4"
  },
  {
    "id": "VID-20231009-WA0006",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0006.mp4"
  },
  {
    "id": "VID-20231009-WA0007",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0007.mp4"
  },
  {
    "id": "VID-20231009-WA0008",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0008.mp4"
  },
  {
    "id": "VID-20231009-WA0009",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0009.mp4"
  },
  {
    "id": "VID-20231009-WA0010",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0010.mp4"
  },
  {
    "id": "VID-20231009-WA0011",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0011.mp4"
  },
  {
    "id": "VID-20231009-WA0012",
    "url": "https://svaluminium.s3.amazonaws.com/mosquito/VID-20231009-WA0012.mp4"
  }
]
}
]

export default videos
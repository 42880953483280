import React from 'react'
import SvLogo from '../../assets/svlog.svg'
import Map from '../../assets/map.svg'
import Mail from '../../assets/mail.svg'
import Phone from '../../assets/phone-call.svg'

const Footer = () => {
  return (
    <div>
      <div className="h-full w-full flex-shrink-0 bg-customGray text-white p-6">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <img src={SvLogo} className="w-40 h-40 mb-4 sm:mb-0" />
          <div className="flex flex-col gap-2">
            <div className="flex justify-center items-center gap-5 text-base sm:text-lg">
              <img src={Map} />
              <div>
                <div>No.10, Amma Complex, North Selvavinayagapuram,</div>
                <div>Pavoorchatram, Tenkasi district(627808).</div>
              </div>
            </div>
            <div className="flex gap-5 text-base sm:text-lg">
              <img src={Phone} />
              <div>+91 9448052826</div>
            </div>
            <div className="flex gap-5 text-base sm:text-lg">
              <img src={Mail} />
              <div>sv.intiriors45@gmail.com</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center mt-4 sm:mt-0">
          <div className="text-base sm:text-lg">
            © 2023 SV Aluminium Doors & Windows
          </div>
          <div className="break-words">
            All rights reserved. All trademarks are property of their respective
            owners.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
